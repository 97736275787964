import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {

  connect() {
    this.backdrop = document.querySelector(".popup-backdrop")
    this.backdrop.classList.remove("active")
  }
  
  edit_document(e){
    this.container = document.querySelector(`.${e.currentTarget.getAttribute('data-button-control')}`);
    this.backdrop.classList.add("active")
    this.container.classList.add("active")
    this.container.querySelectorAll('input[type="text"]').forEach(x=>x.removeAttribute('readonly'))
    this.container.querySelectorAll('input[type="checkbox"],select').forEach(x=>x.removeAttribute('disabled'))
    this.container.querySelectorAll('.admin-upload-area').forEach(element => {
      element.classList.add('active')
    })
    this.container.querySelectorAll('.zoom-button').forEach(element => {
      element.classList.add('disabled')
    })
    this.container.querySelectorAll('.content-docto__img').forEach(element => {
      if(!element.classList.contains('attached')) {
        element.classList.add('d-none')
      }
    })
  }

  cancelar_document(e){
    this.backdrop.classList.remove("active")
    this.container.classList.remove("active")
    this.container.querySelectorAll('input[type="text"]').forEach(input => input.setAttribute('readonly','true'))
    this.container.querySelectorAll('input[type="checkbox"],select').forEach(x=>x.setAttribute('disabled',"true"))
    this.container.querySelectorAll('.admin-upload-area').forEach(element => {
      element.classList.remove('active')
    })
    this.container.querySelectorAll('.zoom-button').forEach(element => {
      element.classList.remove('disabled')
    })
    this.container.querySelectorAll('.content-docto__img').forEach(element => {
      if(!element.classList.contains('attached')) {
        element.classList.remove('d-none')
      }
    })
  }
}
