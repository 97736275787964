import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['latitudeField', 'longitudeField']

  connect() {
    if (!navigator.geolocation) {
      console.error(`Your browser doesn't support Geolocation`);
    } else {
      //this.setCoords()
    }
  }

  setCoords() {
    if(this.latitudeField.value !== '' && this.longitudeField.value !== '') {
      return
    }

    let options = { latitude: this.latitudeField, longitude: this.longitudeField }
    navigator.geolocation.getCurrentPosition(this.onSuccess, this.onError, options)
  }

  onSuccess(position){
    // Can't access to targets from this function
    const latitudeTarget = document.getElementById('loan_application_address_attributes_latitude')
    const longitudeTarget = document.getElementById('loan_application_address_attributes_longitude')
    latitudeTarget.value = position.coords.latitude
    longitudeTarget.value = position.coords.longitude
  }

  onError() {
    console.log('An error ocurred while fetching coords')
  }

  get latitudeField() {
    return this.latitudeFieldTarget
  }

  get longitudeField() {
    return this.longitudeFieldTarget
  }
}
